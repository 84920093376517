<template>
  <v-timeline dense v-if="mobileScreen">
    <v-timeline-item
      v-for="(event, i) in eventSchedule"
      :key="i"
      :color="tableColors[i % tableColors.length]"
      :class="`${tableColors[i % tableColors.length]}--text`"
      style="font-size: 12px"
      small
    >
      <v-row no-gutters>
        <v-col cols="4">
          <strong>{{ event.time }}</strong>
        </v-col>
        <v-col grow>
          <strong>{{ event.title }}</strong>
          <!-- <div class="caption">{{event.description}}</div> -->
        </v-col>
      </v-row>
    </v-timeline-item>
  </v-timeline>
  <v-timeline v-else>
    <v-timeline-item
      v-for="(event, i) in eventSchedule"
      :key="i"
      :color="tableColors[i % tableColors.length]"
      right
      small
    >
      <template v-slot:opposite>
        <span
          :class="
            `headline font-weight-bold ${
              tableColors[i % tableColors.length]
            }--text`
          "
          v-text="event.time"
        ></span>
      </template>
      <div class="py-0">
        <h2
          :class="
            `headline font-weight-light mb-4 ${
              tableColors[i % tableColors.length]
            }--text`
          "
        >
          {{ event.title }}
        </h2>
        <ul>
          <li v-for="desc in event.description" class="white--text" :key="desc">
            {{ desc }}
          </li>
        </ul>
      </div>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { eventSchedule } from "../eventInfo.js";
export default {
  data: () => ({
    eventSchedule: eventSchedule,
    tableColors: ["cyan", "green", "amber", "orange"]
  }),
  computed: {
    mobileScreen() {
      return this.$vuetify.breakpoint.xs;
    }
  }
};
</script>
