export const registerLink =
  "https://www.eventbrite.com/e/women-in-data-science-philadelphia-2021-tickets-137655327791";

export const eventDate = {
  // Human readable date
  date: "March 8, 2021",
  // Machine readable date (used so that search engines can just grab the date from our site and use it)
  // YYYY-MM-DD
  machineReadableDate: "2021-03-08"
};

export const corporateSponsorImages = [
  require("./assets/sponsors/VFV.svg"),
  require("./assets/sponsors/chop.png"),
  require("./assets/sponsors/CSLBehring.jpeg"),
  require("./assets/sponsors/gsk.jpg"),
  require("./assets/sponsors/guru.png"),
  require("./assets/sponsors/jnj.png"),
  require("./assets/sponsors/merck.png"),
  require("./assets/sponsors/wawa.jpeg")
  // require("./assets/sponsors/BMC_black_stacked.jpg")
  // require("./assets/sponsors/CSLBehring.png"),
  // require("./assets/CodedbyKids_logo.png")
];

export const uniSponsorImages = [
  require("./assets/sponsors/stevens.png"),
  require("./assets/sponsors/UPenn.png"),
  require("./assets/sponsors/wharton.png")
];

export const eventSchedule = [
  {
    title: "Arrival & Breakfast",
    description: [],
    time: "9:00 AM"
  },
  {
    title: "Introduction to WiDS Philadelphia",
    description: ["Flora Huang (Vanguard)"],
    time: "9:30 AM"
  },
  {
    title: "Keynote: Insights from the Data Science Frontier",
    description: [
      "Wendy Zhang, Global Head of Data and Analytics - Enterprise Functions at CSL Behring"
    ],
    time: "9:40 AM"
  },
  {
    title: "New Horizons in Bioscience Research",
    description: [
      "Chin-Wen Chang, Ph.D. - Senior Manager Data Science, J&J Medical Device Technology",
      "Huixi Violet Zhang - Associate Director of Genomic Data Science at GSK"
    ],
    time: "10:00 AM"
  },
  {
    title: "Innovation Lightning Talks",
    description: [
      "Ariana Familiar - Supervisory Data Scientist & Imaging Platform Lead at Children's Hospital of Philadelphia",
      "Madeleine Georges - Senior Data Scientist at Vanguard",
      "Ada Aka, Incoming Assistant Professor at Stanford University, PhD in Marketing & Psychology at The Wharton School, University of Pennsylvania "
      // " 11:40-12:00: Flora Huang (Investment Management FinTech Data Scientist, Vanguard)"
    ],
    time: "11:00 AM"
  },
  {
    title: "Lunch Break",
    description: ["Networking & Recruiting Events"],
    time: "12:00 PM"
  },
  {
    title: "Breaking into Data Science",
    description: [
      "Nina Ning Xu, Ph.D. - Data Scientist at Guru",
      "Briyonne Heim - Associate Director, Data Insights, US Oncology Omnichannel Strategy at Merck",
      "Hong Qiu, Head of Marketing Data Science at Vanguard"
    ],
    time: "1:00 PM"
  },
  {
    title: "Mastering Your Data Science Career",
    description: [
      "Kelly Brokate - Head of Data Science at Wawa, Inc.",
      "Wendy Zhang - Global Head of Data and Analytics - Enterprise Functions at CSL Behring",
      "Joelle Saad-Lessler - Associate Dean of Undergraduates, School of Business, Stevens Institute of Technology"
    ],
    time: "2:00 PM"
  },
  {
    title: "Networking Session",
    description: [],
    time: "3:00 PM"
  }
];

export const widsLinks = [
  // Icon here refers to the name of Material Design Icons
  {
    icon: "mdi-twitter",
    link: "https://twitter.com/WiDS_Worldwide"
  },
  {
    icon: "mdi-facebook",
    link: "https://www.facebook.com/WiDSWorldwide"
  },
  {
    icon: "mdi-linkedin",
    link:
      "https://www.linkedin.com/company/women-in-data-science-wids-at-stanford-university/"
  },
  {
    icon: "mdi-instagram",
    link: "https://www.instagram.com/wids_worldwide/"
  },
  {
    icon: "mdi-youtube",
    link: "https://www.youtube.com/channel/UCbyzlQEnV1_MYM4Cbp_7eWg"
  }
];

// NOTE: if you're changing this, you will also probably want to change the Google Maps directions at the bottom.
// For those, search the location up on Google Maps. Click the sidebar -> "Embed or share map" -> "Embed map"
// Do this twice for both screen sizes and replace the "iframe" components found at the bottom of Home.vue
export const locationAddress = {
  line1: "Quorum",
  line2: "3675 Market Street, Suite 400",
  line3: "Philadelphia, PA 19104"
};

// export const headerImage = require("./assets/web_header_green_wide.png")
